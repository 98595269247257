<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addCorrectActionDetails" class="w-100">
      <div class="row">
        <div class="col-12 text-right">
          <div class="row justify-content-end">
            <div class="col-auto" v-if="isAllowed('accident-incident.corrective-action.edit') && isOwnVessel()">
            <button class="e-btn e-btn-outlined-red ml-1" type="button" v-show="toEdit" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-1" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="submit" v-show="toEdit" :disabled="hasChanges === false">Save</button>
              <button class="e-btn e-btn-green ml-1" type="button" v-show="!toEdit" @click="editValues">Edit Corrective Action</button>
            </div>
            <div class="col-auto" v-if="isAllowed('accident-incident.corrective-action.download.car') && isOwnVessel()">
              <div class="dropdown dropright mb-2" v-if="!toEdit">
                <button
                  id="actionDropdown"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="e-btn e-btn-blue-gray force-white-all"
                  data-toggle="dropdown"
                >
                  <font-awesome-icon class="mr-1" icon="download"/> Download Corrective Action Report
                </button>
                <div aria-labelledby="actionDropdown" class="dropdown-menu">
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button"  @click="generateCARReport('xlsx')">
                    <font-awesome-icon icon="file-excel" />
                    Excel
                  </button>
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="generateCARReport('pdf')">
                    <font-awesome-icon icon="file-pdf" />
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <h6 class="font-weight-bold">Root Cause</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 25%">a) Root Cause</td>
            <td>
              {{
                accidentIncidentReport.cause.rootCause ?   accidentIncidentReport.cause.rootCause : ''
              }}
            </td>
          </tr>
          <tr>
            <td class="pl-4" style="width: 25%">b) Factor of Root Cause</td>
            <td class="pl-4">
              {{
                accidentIncidentReport.cause.rootCauseFactor ? accidentIncidentReport.cause.rootCauseFactor : ''
              }}
            </td>
          </tr>
        </table>
      </div>
      <hr />

      <h6 class="font-weight-bold pt-3">Preparation of Corrective Actions</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 25%" class="font-weight-bold">Instruction to the Master to take corrective action  <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td v-if="toEdit">
              <vue-editor  v-model="correctAction.instruction" :editor-toolbar="toolbar" ></vue-editor>
            </td>
            <td v-else>
              <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="accidentIncidentReport.corrective.instruction || '' " />
            </td>
          </tr>
          <!--  todo attachment   -->
          <tr>
            <td style="width: 25%">Attached any other instruction</td>
            <td>
              <div class="row pt-1 pb-1">
                <div class="col-12 text-right" v-if="toEdit">
                  <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"
                          data-toggle="modal" data-target="#aircaPreparationFileUpload" >
                    <font-awesome-icon class="mr-1" icon="plus"/>
                    Add Attachment
                  </button>
                  <button type="button" class="e-btn btn-xs e-btn-blue ml-2 px-5 e-text-white" @click="downloadAllAttachments(preparation)"
                          :disabled="preparationFiles.length === 0"
                          :class="preparationFiles.length === 0 ? 'e-btn-blue-disabled' : ''"
                  >
                    <font-awesome-icon class="mr-1" icon="download"/>
                    Download All Documents
                  </button>
                </div>
                  <!--  todo button fix   -->
                <div class="col-2 mb-1 mt-2" v-for="file in mergedPreparationFiles">
                    <div id="attachment-card" class="card py-1" :title="file.attachment_name">
                      <div class="dropdown dropright mb-2">
                        <div
                          data-toggle="dropdown"
                          id="attachmentDropdown"
                          class="text-center"
                          aria-haspopup="true" style="cursor:pointer"
                        >
                          <div class="card-img-top text-center">
                            <img :src="file.thumbnail" alt="Attachment Thumbnail" style="width: 50px" />
                          </div>
                          <small id="attachment-name">{{file.attachment_name}}</small>
                          <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
                            <button class="dropdown-item text-secondary font-weight-bolder"
                                    type="button"
                                    v-if="(file.extension === 'pdf' || file.mime_type.includes('image')) && file.id !== null"
                                    @click="viewCAFile(file.id,file)"
                            >
                              <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
                              View Attachment
                            </button>
                            <button class="dropdown-item text-secondary font-weight-bolder"
                                    @click="downloadCAFile(file.id,file)"
                                    type="button"
                                    v-if="file.id !== null"
                            >
                              <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
                              Download Attachment
                            </button>
                            <button class="dropdown-item  text-danger font-weight-bolder"
                                    @click="deleteCAFile(file.id,file)"
                                    v-if="toEdit"
                                    type="button"
                            >
                              <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
                              Delete Attachment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Date of Instruction  <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="pt-2" v-if="toEdit">
              <input type="date" class="form-control form-control-sm w-auto mb-2"
                     :min="minimumDate"
                     :max="currentDate"
                     v-model="correctAction.instruction_date">
            </td>
            <td class="pt-2" v-else>
              <p class="bg-light rounded mb-0 p-2" v-if="accidentIncidentReport.corrective.instruction_date">
                {{ accidentIncidentReport.corrective.instruction_date | moment('D MMM YYYY') }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Instructed by (Person In Charge)</td>
            <td class="text-capitalize font-weight-bold pb-0">
              <span>{{accidentIncidentReport.personInCharge}}</span>
            </td>
          </tr>
        </table>
      </div>

      <hr />
      <h6 class="font-weight-bold pt-3">Report of Corrective Actions taken (or to be taken immediately and to be reported with one(1) month)</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 25%" class="font-weight-bold">Action taken by the Management office and/or the Master <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td v-if="toEdit">
              <vue-editor  v-model="correctAction.action_taken" :editor-toolbar="toolbar" ></vue-editor>
            </td>
            <td v-else>
              <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="accidentIncidentReport.corrective.action_taken || '' " />
            </td>
          </tr>
          <!--  todo attachment   -->
          <tr>
            <td style="width: 25%">Attached any other detailed report</td>
            <td>
              <div class="row pt-1">
                <div class="col-12 pb-2 text-right">
                  <button type="button" class="e-btn btn-xs e-btn-green ml-2 px-5 e-text-white"
                          data-toggle="modal" data-target="#aircaVerificationFileUpload"  v-if="toEdit">
                    <font-awesome-icon class="mr-1" icon="plus"/>
                    Add Attachment
                  </button>
                  <button type="button" class="e-btn btn-xs e-btn-blue ml-2 px-5 e-text-white" v-if="toEdit" @click="downloadAllAttachments(verification)"
                          :disabled="verificationFiles.length === 0"
                          :class="verificationFiles.length === 0 ? 'e-btn-blue-disabled' : ''"
                  >
                    <font-awesome-icon class="mr-1" icon="download"/>
                    Download All Documents
                  </button>
                </div>
                <div class="col-2 mb-1 mt-2" v-for="file in mergedVerificationFiles">
                    <div id="attachment-card" class="card py-1" :title="file.attachment_name">
                      <div class="dropdown dropright mb-2">
                        <div
                          data-toggle="dropdown"
                          id="attachmentDropdown"
                          class="text-center"
                          aria-haspopup="true" style="cursor:pointer"
                        >
                          <div class="card-img-top text-center">
                            <img :src="file.thumbnail" alt="Attachment Thumbnail" style="width: 50px" />
                          </div>
                          <small id="attachment-name">{{file.attachment_name}}</small>
                          <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
                            <button class="dropdown-item text-secondary font-weight-bolder"
                                    type="button"
                                    v-if="(file.extension === 'pdf' || file.mime_type.includes('image')) && file.id !== null"
                                    @click="viewCAFile(file.id,file)"
                            >
                              <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
                              View Attachment
                            </button>
                            <button class="dropdown-item text-secondary font-weight-bolder"
                                    @click="downloadCAFile(file.id,file)"
                                    v-if="file.id !== null"
                                    type="button"
                            >
                              <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
                              Download Attachment
                            </button>
                            <button class="dropdown-item  text-danger font-weight-bolder"
                                    v-if="toEdit"
                                    @click="deleteCAFile(file.id,file)"
                                    type="button"
                            >
                              <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
                              Delete Attachment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Date of Report <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="pt-2" v-if="toEdit">
              <input type="date" class="form-control form-control-sm w-auto"
                     :min="minimumDate"
                     :max="currentDate"
                     v-model="correctAction.action_taken_date">
            </td>
            <td class="pt-2" v-else>
              <p class="bg-light rounded mb-0 p-2" v-if="accidentIncidentReport.corrective.action_taken_date">
                {{ accidentIncidentReport.corrective.action_taken_date | moment('D MMM YYYY') }}
              </p>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Reported by <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="pt-2" v-if="toEdit">
              <input type="text" class="form-control form-control-sm w-auto"
                     v-model="correctAction.reported_by">
            </td>
            <td class="pt-2" v-else>
              <p class="bg-light rounded mb-0 p-2" v-if="accidentIncidentReport.corrective.reported_by">
                {{ accidentIncidentReport.corrective.reported_by }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <hr />
      <h6 class="font-weight-bold pt-3">Verification of effectiveness for Corrective Action</h6>
      <div class="w-100 pl-3">
        <table class="w-100">
          <tr>
            <td style="width: 40%">Corrective action is affective to keep suitable level appropriate to the risk of problem encountered. (Case is completed and closed)</td>
            <td v-if="toEdit">
              <select class="form-control w-auto form-control-sm my-2" name="action-closed" id="actionClosed"
                      v-model="correctAction.is_corrective_action_affective" @change="correctiveActionAffectiveness">
                <option :value="0">YES</option>
                <option :value="1">NO</option>
              </select>
            </td>
            <td v-else>
              <p class="bg-light rounded mb-1 p-2">
                {{accidentIncidentReport.corrective.is_corrective_action_affective == 1 ? 'NO' : 'YES'}}
              </p>
            </td>
          </tr>
        </table>
        <table class="w-100 mt-3" v-if="(!toEdit && accidentIncidentReport.corrective.is_corrective_action_affective) || (correctAction.is_corrective_action_affective && toEdit)">
          <tr>
            <td style="width: 40%">Verification of effectiveness for Corrective Action shall be carried out again because of difficulties to do so:</td>
            <td v-if="toEdit">
              <select class="form-control w-auto form-control-sm my-2" v-model="correctAction.is_verified_corrective_action" @change="correctAction.next_verification_date = ''">
                <option value="0">NO</option>
                <option value="1">YES</option>
              </select>
            </td>
            <td v-else class="pl-2">
              <p class="bg-light rounded mb-1 p-2">
                {{accidentIncidentReport.corrective.is_verified_corrective_action == 1 ? 'YES' : 'NO'}}
              </p>
            </td>
          </tr>
          <tr v-if="parseInt(correctAction.is_verified_corrective_action) === 1">
            <td style="width: 40%" class="text-right pr-3">If Yes, the date of next verification</td>
            <td v-if="toEdit">
              <input type="date" :min="currentDate" class="form-control form-control-sm w-auto" v-model="correctAction.next_verification_date">
            </td>
            <td v-else class="pl-2">
              <p class="bg-light rounded mb-0 p-2" v-if="accidentIncidentReport.corrective.next_verification_date">
                {{ accidentIncidentReport.corrective.next_verification_date | moment('D MMM YYYY') }}
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="pt-3"></td>
          </tr>
          <tr>
            <td style="width: 40%">Effectiveness for Corrective Action has not been confirmed, therefore further investigation of root cause, planning and instruction of another corrective action to be done.</td>
            <td v-if="toEdit">
              <select class="form-control w-auto form-control-sm my-2" v-model="correctAction.is_corrective_action_effective">
                <option value="0">NO</option>
                <option value="1">YES</option>
              </select>
            </td>
            <td v-else class="pl-2">
              <p class="bg-light rounded mb-0 p-2">
                {{accidentIncidentReport.corrective.is_corrective_action_effective == 1 ? 'YES' : 'NO'}}
              </p>
            </td>
          </tr>
        </table>
        <table class="w-100 mt-3">
          <tr>
            <td style="width: 25%">
              Date of Verification
            </td>
            <td v-if="toEdit" style="width: 25%">
              <input type="date" class="form-control form-control-sm w-auto"
                     :min="minimumDate"
                     :max="currentDate"
                     v-model="correctAction.verification_date">
            </td>
            <td v-else class="pr-2">
              <p class="bg-light rounded mb-0 p-2"  v-if="accidentIncidentReport.corrective.verification_date">
                {{ accidentIncidentReport.corrective.verification_date | moment('D MMM YYYY') }}
              </p>
            </td>
            <td style="width: 50%">
              <div class="row w-100 align-items-center">
                <div class="col-xl-3 col-lg-4 col-md-6">P.I.C. or Superintendent</div>
                <div class="col-xl-9 col-lg-8 col-md-6">
                  <p class="bg-light rounded mb-0 p-2 font-weight-bold" v-if="accidentIncidentReport.personInCharge">
                    {{accidentIncidentReport.personInCharge}}
                  </p>
                </div>
              </div>
              <div class="row w-100 align-items-center">
                <div class="col-xl-3 col-lg-4 col-md-6">
                  D.P.
                </div>
                <div class="col-xl-9 col-lg-8 col-md-6">
                  <p class="bg-light rounded mb-0 p-2 font-weight-bold">
                    Takimoto , S.
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>


    </form>
    <!-- Modal -->
    <div class="modal fade" id="aircaPreparationFileUpload" tabindex="-1" role="dialog" aria-labelledby="aircaPreparationFileUploadTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="handleChange('preparationAttachmentInput','aircaPreparationFileUpload')">
          <div class="modal-header">
            <h5 class="modal-title" id="aircaPreparationFileUploadTitle">Preparation of Corrective Actions — Instruction Attachment</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="h6 w-100 font-weight-bold" for="preparationAttachmentInput">Instruction Attachment</label>
              <input type="file" class="form-control form-control-file" id="preparationAttachmentInput"
                     accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                     multiple required>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="e-btn e-btn-blue-gray btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="e-btn e-btn-green text-white">
              Add Files
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="aircaVerificationFileUpload" tabindex="-1" role="dialog" aria-labelledby="aircaVerificationFileUploadTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="handleChange('verificationAttachmentInput','aircaVerificationFileUpload')">
          <div class="modal-header">
            <h5 class="modal-title" id="aircaVerificationFileUploadTitle">Report of Corrective Actions Taken Attachment</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="h6 w-100 font-weight-bold" for="verificationAttachmentInput">Actions Taken Attachment</label>
              <input type="file" class="form-control form-control-file" id="verificationAttachmentInput"
                     accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                     multiple="multiple" required>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="e-btn e-btn-blue-gray btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="e-btn e-btn-green text-white">
              Add Files
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {API_URL} from "@/services/ConstantService";
import {FileService} from "@/services/FileService";
import DateMixin from "@/mixins/DateMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import {DataService} from "@/services/DataService";

const {ReportService} = require('../../../services/ReportService')
const {AlertService} = require('../../../services/AlertService')

export default {
  name: 'CorrectiveAction',
  mixins:[DateMixin,permissionMixin],
  data() {
    return {
      preparation : 1,
      verification : 2,
      correctAction: {},
      reportId: this.$route.params.id,
      preparationFiles: [],
      verificationFiles: [],
      attachments: [],
      toEdit: false,
      toDownloadCAR: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      newPreparation : [],
      newVerification : [],
      newOtherFiles : [],
      attachmentIdsToDelete : []
    }
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport','updateAccidentIncidentCorrective']),
    async resetAttachments() {
      await this.getPreventionFiles()
      await this.getVerificationFiles()
      this.newPreparation = [];
      this.newVerification = [];
      this.newOtherFiles = [];
      this.attachmentIdsToDelete = [];
    },
    async addCorrectActionDetails() {
      if(await AlertService.confirmUpdateAlert()) {
        this.correctAction.id = this.reportId;
        var result= await this.updateAccidentIncidentCorrective(this.correctAction);
        await this.uploadNewAttachments();
        await this.requestFileDeletion();
        await this.resetAttachments();
        if(result){
          AlertService.successAlert('CORRECTIVE ACTION DETAILS UPDATED', 'UPDATE CORRECTIVE ACTION DETAILS')
          await this.getAccidentIncidentReport(this.reportId)
          this.toEdit = false
        }
      }
    },
    async editValues() {
      this.toEdit = true
      if (this.toEdit) {
        this.correctAction = Object.assign({}, this.accidentIncidentReport.corrective)
      }
    },
    async uploadNewAttachments() {
      if (this.newPreparation.length > 0) {
        let preparationFormData = new FormData();
        this.newPreparation.forEach(item => {
          preparationFormData.append('attachments[]', item.file)
          preparationFormData.append('air_ca_file_type_id', item.air_ca_file_type_id)
        });

        await ReportService.addCAFiles(preparationFormData, this.reportId);
      }
      if (this.newVerification.length > 0) {
        let verificationFormData = new FormData();
        this.newVerification.forEach(item => {
          verificationFormData.append('attachments[]', item.file)
          verificationFormData.append('air_ca_file_type_id', item.air_ca_file_type_id)
        });
        await ReportService.addCAFiles(verificationFormData, this.reportId);
      }
    },
    async handleChange(inputID,modal){
      let inputFile = document.getElementById(inputID).files
      if (inputFile) {
        for (let i = 0; i < inputFile.length; i++ ){
          let fileType = DataService.checkFileType(inputFile[i]);
          if (inputID === 'preparationAttachmentInput') {
            const newAttachment = {
              id                  : null,
              air_ca_file_type_id : this.preparation,
              air_corrective_id   : parseInt(this.reportId),
              attachment_name     : inputFile[i].name,
              extension           : fileType,
              mime_type           : inputFile[i].type,
              thumbnail           : require(`@/assets/thumbnail/` + fileType + '.png'),
              file_index          : i,
              file                : inputFile[i]
            };
            this.newPreparation.push(newAttachment);
          } else if (inputID === 'verificationAttachmentInput') {
            const newAttachment = {
              id                  : null,
              air_ca_file_type_id : this.verification,
              air_corrective_id   : parseInt(this.reportId),
              attachment_name     : inputFile[i].name,
              extension           : fileType,
              mime_type           : inputFile[i].type,
              thumbnail           : require(`@/assets/thumbnail/` + fileType + '.png'),
              file_index          : i,
              file                : inputFile[i]
            };
            this.newVerification.push(newAttachment);
          } else {
            const newAttachment = {
              id                  : null,
              air_ca_file_type_id : null,
              air_corrective_id   : parseInt(this.reportId),
              attachment_name     : inputFile[i].name,
              extension           : fileType,
              mime_type           : inputFile[i].type,
              thumbnail           : require(`@/assets/thumbnail/noext.png`),
              file_index          : i,
              file                : inputFile[i]
            };
            this.newOtherFiles.push(newAttachment);
          }
        }

        $(`#${modal}`).modal('hide');
        document.getElementById(inputID).value = null
      }
    },

    correctiveActionAffectiveness(){
      if(!this.correctAction.is_corrective_action_affective){
        this.correctAction.is_corrective_action_effective = 0;
        this.correctAction.is_verified_corrective_action= 0;
        this.correctAction.next_verification_date=null;
      }
    },

    async getPreventionFiles(){
      let preparationFiles = await ReportService.getCAFiles(this.reportId, this.preparation)
      if (preparationFiles) this.preparationFiles = preparationFiles
    },

    async getVerificationFiles(){
      let verificationFiles = await ReportService.getCAFiles(this.reportId, this.verification)
      if (verificationFiles) this.verificationFiles = verificationFiles
    },

    async downloadCAFile(fileID, fileInfo){
      swal.fire({
        title:`Downloading file... <br> Please Wait`
      })
      swal.showLoading();
      let file = await ReportService.downloadCAFiles(this.reportId, fileID)
      if(file){
        swal.close();
        let fileBlob =URL.createObjectURL(FileService.base64FileToBlob(file.data,fileInfo.mime_type));
        let docUrl = document.createElement('a');
        docUrl.href = fileBlob;
        docUrl.setAttribute('download', fileInfo.attachment_name);
        document.body.appendChild(docUrl);
        docUrl.click();
      }

    },

    async viewCAFile(fileID,fileInfo){

      let file = await ReportService.downloadCAFiles(this.reportId, fileID)
      let fileBlob = FileService.base64FileToBlob(file.data,fileInfo.mime_type);
      let docUrl = document.createElement('a');
      docUrl.href = URL.createObjectURL(fileBlob);
      docUrl.setAttribute('target', '_blank');
      document.body.appendChild(docUrl);
      docUrl.click();
    },

    removeAddedAttachment(fileInfo) {
      if (fileInfo.air_ca_file_type_id === this.preparation) {
        this.newPreparation = this.newPreparation.filter(item => item.file_index !== fileInfo.file_index);
      } else if (fileInfo.air_ca_file_type_id === this.verification) {
        this.newVerification = this.newVerification.filter(item => item.file_index !== fileInfo.file_index);
      }
    },
    async requestFileDeletion() {
      for (let counter = 0; counter < this.attachmentIdsToDelete.length; counter++) {
        await ReportService.deleteCAFile(this.reportId, this.attachmentIdsToDelete[counter]);
      }
    },
    async deleteCAFile(fileID,fileInfo){
      if (fileID === null) {
        return this.removeAddedAttachment(fileInfo)
      }
      if(fileInfo.air_ca_file_type_id === this.preparation) this.preparationFiles=this.preparationFiles.filter(file=>file.id!==fileInfo.id)
      if(fileInfo.air_ca_file_type_id === this.verification) this.verificationFiles=this.verificationFiles.filter(file=>file.id!==fileInfo.id)
      this.attachmentIdsToDelete.push(fileID);
    },

    async downloadAllAttachments(airCAFileType){
      swal.fire({
        title:`Downloading all attachments... <br> Please Wait`
      });
      swal.showLoading();
      let file = await ReportService.downloadCAFileAll(this.reportId, airCAFileType)
      if(file){
        swal.close();
        let fileBlob = window.URL.createObjectURL(new Blob([file.data], {type: "octet/stream"}));
        let docUrl = document.createElement('a');
        docUrl.href = fileBlob;
        var fileName='Ca_Attachments';
        if(airCAFileType === 1) fileName +='_Preparation_';
        if(airCAFileType === 2) fileName +='_Action_Taken_';
        fileName += this.$route.params.id;

        const dateToday=parseInt(new Date().getTime() / 1000 );
        fileName += `_${dateToday}`;
        docUrl.setAttribute('download', fileName+'.zip');
        document.body.appendChild(docUrl);
        docUrl.click();
      }
    },

    async cancel(){
      if (this.hasChanges === false) {
        this.toEdit = false;
      } else {
        if (await AlertService.cancelAlert()) {
          this.toEdit = false;
          await this.resetAttachments();
          this.resetPersonnels()
        }
      }
    },
    downloadCAR() {
      this.toDownloadCAR = true;
    },

    async generateCARReport(type = 'xlsx') {
      swal.fire({title: 'Please wait'});
      swal.showLoading();

      let filename = 'Corrective_Action_Report_R1_' + this.accidentIncidentReport.code + '_' + Date.now() + '.' + type;
      let mime_type = (type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel');
      let file = await ReportService.generateCARReport(this.reportId, type)
      let url = window.URL.createObjectURL(new Blob([file.data],{type:mime_type}));

      const link = document.createElement('a');
      link.setAttribute('href', url);

      if (type === 'pdf') {
        link.setAttribute('target', '_blank');
      } else {
        link.setAttribute('download', filename);
      }

      this.toDownloadCAR = false;
      swal.close();
      document.body.appendChild(link);
      link.click();
    },


  },
  async mounted() {
    await this.getPreventionFiles()
    await this.getVerificationFiles()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['accidentIncidentReport', 'userKeys']),
    mergedPreparationFiles() {
      return [...this.preparationFiles, ...this.newPreparation]
    },
    mergedVerificationFiles() {
      return [...this.verificationFiles, ...this.newVerification]
    },
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let editedData = {
        instruction                    : this.correctAction.instruction,
        instruction_date               : this.correctAction.instruction_date,
        action_taken                   : this.correctAction.action_taken,
        action_taken_date              : this.correctAction.action_taken_date,
        reported_by                    : this.correctAction.reported_by,
        is_corrective_action_affective : parseInt(this.correctAction.is_corrective_action_affective),
        is_verified_corrective_action  : parseInt(this.correctAction.is_verified_corrective_action),
        next_verification_date         : this.correctAction.next_verification_date,
        is_corrective_action_effective : parseInt(this.correctAction.is_corrective_action_effective),
        verification_date              : this.correctAction.verification_date
      };

      return this.attachmentIdsToDelete.length > 0 || this.newPreparation.length > 0 || this.newVerification.length > 0 || this.newOtherFiles.length < 0 || DataService.checkIfChanged(this.accidentIncidentReport.corrective, editedData);
    }
  },
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}

#attachment-card:hover > .dropdown small {
  color: white;
}

#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}

#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
